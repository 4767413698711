import { ref, reactive, onMounted } from 'vue';
import { FileOutlined } from '@ant-design/icons-vue';
export default {
  components: {
    FileOutlined
  },

  setup() {
    return {};
  }

};